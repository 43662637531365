<template>
  <div class="auth-wrapper">
    <b-row class="auth-inner err404">
      <b-col sm="12" md="6" lg="3">
        <b-img src="@/assets/images/logo.svg" />

        <h2 class="mb-1 mt-4">Page Not Found</h2>
        <p class="mb-2">The requested URL was not found on this server.</p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/' }"
        >
          Back to home
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BButton, BImg } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BImg,
  },
  data() {
    return {}
  },
}
</script>
